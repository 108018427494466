import React from 'react';
import styled from 'styled-components';

import { margins } from '../home/v2/styledComponents';
import { fontSizes, mobileThresholdPixels } from '../home/v3/styledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${margins.l} 0px;
`;

const Title2 = styled.h2`
  font-family: Libre Baskerville;
  font-size: 32px;
  line-height: 34px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
  }
`;

const Span = styled.span`
  font-size: ${fontSizes.m};
  line-height: 25px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 20px;
  }
`;

const IntroBlock = () => (
  <Container>
    <Title2>Fitle & Tilli s’associent pour créer la parfaite expérience shopping online</Title2>
    <Span>
      Le taux de retour moyen en e-commerce en France est de 27% en moyenne (IFM).
      On note que 70% des retours sont dûs à un problème de fit, donc soit la taille
      initialement sélectionnée sur le site n’est pas la bonne soit la coupe du vêtement
      ne fit pas la morphologie du client. Charles Nouboué & Beryl de Labouchere, fondateurs
      respectifs des solutions Fitle et Tilli, annoncent aujourd’hui la signature d’un
      partenariat entre leurs 2 entreprises visant à proposer aux sites de vente en ligne de
      vêtements une expérience shopping réinventée.
    </Span>
  </Container>
);

export default IntroBlock;
