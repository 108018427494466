// cSpell:ignore tillixfitle
import React from 'react';
import styled from 'styled-components';

import { margins } from '../home/v2/styledComponents';
import { mobileThresholdPixels, fontSizes } from '../home/v3/styledComponents';

import icon from '../../assets/tillixfitle/target.icon.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${margins.xl} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: 56px 0px;
  }
`;

const TextContainer = styled.div``;

const Img = styled.img`
  object-fit: contain;
  margin-right: 51px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.m};
  }
`;

const Span1 = styled.span`
  font-size: ${fontSizes.m};
  line-height: 23px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 23px;
  }
`;
const Span2 = styled(Span1)`font-weight: 500;`;
const Span3 = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.l};
  line-height: 34px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    line-height: 23px;
  }
`;

const GoalsBlock = () => (
  <Container>
    <Img src={icon} alt="Icon cible" />
    <TextContainer>
      <Span1>L’objectif Fitle</Span1>
      <br />
      <Span3>Une expérience d’achat en ligne aussi naturelle qu’en magasin !</Span3>
      <br />
      <br />
      <Span1>L’objectif Tilli</Span1>
      <br />
      <Span3>Ne plus jamais laisser un client tout seul face à un problème de fit !</Span3>
      <br />
      <br />
      <br />
      <Span2>Combiner les 2 solutions pour réduire vos 70% de problème de taille aujourd’hui !</Span2>
    </TextContainer>
  </Container>
);

export default GoalsBlock;
